.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.App .header {
  flex: auto;
}

.App .body {
  flex: auto;
}

.App img {
  width: 80vmin;
  pointer-events: none;
}

.App .footer {
  width: 80vmin;
  display: flex;
  justify-content: space-between;
}

.App .footer > div {
  padding: 50px;
}

.App a {
  color: inherit;
  text-decoration: none;
}

@media (max-width: 70em) {
  .App .footer {
    width: 80%;
    font-size: 0.6em;  
  }
  .App .footer > div {
    padding: 50px 0px;
  }
}

